<!-- Header-->
<header class="bg-primary py-3 mb-5">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-lg-12">
                <h1 class="display-5 text-white mt-5 mb-2" i18n="@@home_section1_title">Need a new website? <i class="bi bi-globe"></i></h1>
                <p class="lead mb-5 text-white-50" i18n="@@home_section1_description">Affordable Hosting Solutions (AHS) helps small businesses, self employed workers or any individuals that need help creating a professional looking website.</p>
            </div>
        </div>
    </div>
  </header>
<!-- Page Content-->
<div class="container">
    <div class="row">
        <div class="col-md-8 mb-5">
            <h2 i18n="@@home_section2a_title">What We Do</h2>
            <hr />
            <div i18n="@@home_section2a_description">
                <p>At AHS we work personally with small business or individuals who would like to have a presence on the internet but don't have the time or technical expertise to get this done.</p>
                <p>Our focus is in small sites to promote business and individuals that need an affordable option. With predefined website templates our mainly focus is on adding the content necessarelly to complete the website with minimal customization.</p>
                <p>We do all the work for you! Sites can be up and running in a few hours after all information needed is provided.</p>
                <p>There are no contracts and you can cancel anytime.</p>
                <p>See our <b><a [routerLink]="'/gallery'">Gallery</a></b> for our live demos!</p>
                <p>For plan <b>A,B or C</b> pricing information click <b><a [routerLink]="'/pricing'">here</a></b>.</p>
            </div>
        </div>
        <div class="col-md-4 mb-5">
            <h2 i18n="@@home_section2b_title">Ready to start!</h2>
            <hr />
            <div class="container" i18n="@@home_section2b_description">
                <p>
                    Please fill out the <b><a [routerLink]="'/contactus'">Contact Us</a></b> with some description of what you are looking for and someone will reach out to you.
                </p>
            </div>
        </div> 
    </div>
    <!-- Icons Grid-->
    <section class="features-icons bg-light text-center" style="padding-top: 0rem;">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                        <div class="features-icons-icon d-flex">
                            <a [routerLink]="'/gallery'" class="m-auto">
                                <i class="icon-screen-desktop m-auto text-primary"></i>
                            </a>
                        </div>
                        <h3>Gallery</h3>
                        <p class="lead mb-0">See our gallery for a list of our available site demos.</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                        <div class="features-icons-icon d-flex">
                            <a [routerLink]="'/pricing'" class="m-auto">
                                <i class="icon-wallet m-auto text-primary"></i>
                            </a>    
                        </div>
                        <h3>Pricing</h3>
                        <p class="lead mb-0">See our affordable plans with details of what we include. Each plan has a live sample demo.</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="features-icons-item mx-auto mb-0 mb-lg-3">
                        <div class="features-icons-icon d-flex">
                            <a [routerLink]="'/contactus'" class="m-auto">
                                <i class="icon-envelope-letter m-auto text-primary"></i>
                            </a>
                        </div>
                        <h3>Contact us</h3>
                        <p class="lead mb-0">Ready to start? Contact us and we will help you create your new website!</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
  </div>
  
