 <!-- Navigation-->
<nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
  <span class="navbar-brand"><a [routerLink]="'/'">
    <img src="../assets/logo.png" style="max-width: 50px;"></a>
  </span>

  <!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
  <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>

  <!-- Step 2: Add the ngbCollapse directive to the element below. -->
  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse" i18n="@@app_header_navigation_links">
    <ul class="navbar-nav me-auto">
      <li class="nav-item">
        <!-- Step 4: Close the menu when a link is clicked. -->
        <a class="nav-link" [routerLink]="'/home'" (click)="isMenuCollapsed = true" routerLinkActive="activebutton">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'/gallery'" (click)="isMenuCollapsed = true" routerLinkActive="activebutton">Gallery</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'/pricing'" (click)="isMenuCollapsed = true" routerLinkActive="activebutton">Pricing</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'/contactus'" (click)="isMenuCollapsed = true" routerLinkActive="activebutton">Contact Us</a>
      </li>
    </ul>
    <a href="{{href}}">{{languageLinkText}}</a>
  </div>
</nav>

<router-outlet></router-outlet>

<!-- Footer-->
<footer class="py-5 bg-dark"  i18n="@@app_footer">
  <div class="container"><p class="m-0 text-center text-white"><i class="bi bi-power"></i> Created and hosted by A-Hosting Solutions</p></div>
</footer>