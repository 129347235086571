<!-- Header-->
<header class="bg-primary py-1 mb-5">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-lg-12">
                <h1 class="display-5 text-white mt-5 mb-2" i18n="@@contactus_section1_title">How do I get started? <i class="bi bi-lightning"></i></h1>
                <p class="lead mb-5 text-white-50" i18n="@@contactus_section1_description">Is easy to get started, please provide us with your contact information and some description of what are your needs. Someone will reach out to you get this process started.</p>
            </div>
        </div>
    </div>
  </header>
<!-- Page Content-->
<div class="container">
    <div class="row">
        <div class="col-md-8 mb-5">
            <h2 i18n="@@contactus_section2_title" i18n="@@contactus_section1_title">Contact Us</h2>
            <hr />
            <div class="container">
                <form [formGroup]="subscribeForm" (ngSubmit)="onSubmit()" i18n="@@contactus_section1_form">
                    <div class="mb-3">
                        <label for="name" class="form-label">Name</label>
                        <input type="text" class="form-control" id="name" placeholder="Enter your name..." formControlName="name">
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">Email address</label>
                        <input type="email" class="form-control" id="email" placeholder="Enter your email..." formControlName="email">
                    </div>
                    <div class="mb-3">
                        <label for="plan" class="form-label">Pricing Plan</label>
                        <select class="form-control" id="plan" formControlName="plan">
                            <option>Plan A</option>
                            <option>Plan B</option>
                            <option>Plan C</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="subject" class="form-label">Tell us what you need</label>
                        <textarea class="form-control" id="subject" rows="3" placeholder="Provide a description of what are you looking for.." formControlName="subject"></textarea>
                    </div>
                    
                    <input class="btn btn-primary btn-lg" type="submit" value="Submit" [disabled]="!subscribeForm.valid">
                    <div style="padding-top: 5px;">
                        <div class="alert alert-success" role="alert" *ngIf="subscribed">
                      Thanks for contacting us! We will provide information requested.
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

