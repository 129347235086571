<!-- Header-->
<header class="bg-primary py-1 mb-5">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-lg-12">
                <h1 class="display-5 text-white mt-5 mb-2" i18n="@@pricing_section1_title">How much does a website cost? <i class="bi bi-currency-dollar"></i></h1>
                <p class="lead mb-5 text-white-50" i18n="@@pricing_section1_description">AHS gives you predefined templates that make it easier to create the websites by focusing mainly in the content you want to display. As a result of this pricing is more affordable.</p>
            </div>
        </div>
    </div>
  </header>
<!-- Page Content-->
<div class="container">
    <div class="row">
        <div class="col-md-8 mb-5">
            <h2 i18n="@@pricing_section2a_title1">Plans</h2>
            <hr />
            <div i18n="@@pricing_section2a_description1">
                <p>Our plans vary depending on the website template selected. Some templates are more complex that other that require additional work to complete.</p>
                
                <p>Initial cost for all plans include the following:</p>
                <ul>
                    <li>Custom Domain  (like: www.my-domain.com)</li>
                    <li>Domain registration for 1 year</li>
                    <li>Initial website creation and hosting for 3 months</li>
                    <li>Small customizations</li>
                    <li><b>Subscribe</b> or <b>Contact Us</b> email notification feature.</li>
                    <li>Social media links</li>
                    <li>Up to 1 Gb for image storage</li>
                </ul>
                <p>Not included in the inital cost:</p>
                <ul>
                    <li>Domain yearly renewal - $50 a year starting at second year</li>
                    <li>Additional pages and complex customizations</li>
                    <li>Hosting fee after the first 3 months - 10$ a month</li>
                    <li>Additional storage over 1 Gb</li>
                </ul>
                <p><b>No contracts! Cancel anytime.</b></p>
            </div>
            <h3 i18n="@@pricing_section2a_title2">Want to save some money?</h3>
            <div class="container" i18n="@@pricing_section2a_description2">
                <p>
                    For each referral you can get an additional 3 months of free hosting!
                </p>
                <p>We have 3 different plans available at this time and we will be adding more. We have a <b>Live Demo</b> for each of our plans.</p>
            </div>
        </div>
        <div class="col-md-4 mb-5">
            <h2 i18n="@@pricing_section2b_title">Ready to start!</h2>
            <hr />
            <div class="container" i18n="@@pricing_section2b_description">
                <p>
                    Please fill out the <b><a [routerLink]="'/contactus'">Contact Us</a></b> with some description of what you are looking for and someone will reach out to you.
                </p>
            </div>
        </div> 
    </div>
    <div class="row">
        <div *ngFor="let plan of plans" class="col-md-4 mb-5">
          <div class="card h-100">
              <div class="card-body">
                  <h4 class="card-title">{{plan.title}}</h4>
                  
                  <p class="card-text">{{plan.description}}</p>
              </div>
              <div class="card-footer" i18n="@@pricing_section3_card_footer"><a class="btn btn-primary" target="_blank" href="{{plan.url}}">Live Demo!</a></div>
          </div>
        </div>
      </div>
</div>
