export const config = {
    plans : [
        { 
        title:'Plan A - $350',
        description:'The initial cost includes a multipurpose, one page theme featuring a dark color scheme and smooth scrolling animations',
        url: 'https://s3.amazonaws.com/templates.ahostingsolutions.com/startbootstrap-grayscale-gh-pages/index.html'
        },
        {
        title:'Plan B - $450',
        description:'The initial cost includes a  simple, elegant, and beautifully responsive landing page theme for websites',
        url: 'https://s3.amazonaws.com/templates.ahostingsolutions.com/startbootstrap-landing-page-gh-pages/index.html'
        },
        {
        title:'Plan C - $550',
        description:'The initial cost includes a  clean, stylish, one page portfolio theme perfect for your agency or small business',
        url: 'https://s3.amazonaws.com/templates.ahostingsolutions.com/startbootstrap-agency-gh-pages/index.html',}
      ],
    templates : [
        { image:'https://s3.amazonaws.com/templates.ahostingsolutions.com/grayscale.png',
        url: 'https://s3.amazonaws.com/templates.ahostingsolutions.com/startbootstrap-grayscale-gh-pages/index.html',
        title:'Gray Scale (Plan A)',
        description:'A multipurpose, one page theme featuring a dark color scheme and smooth scrolling animations'
        },
        { image:'https://s3.amazonaws.com/templates.ahostingsolutions.com/landingpage.png',
        url: 'https://s3.amazonaws.com/templates.ahostingsolutions.com/startbootstrap-landing-page-gh-pages/index.html',
        title:'Landing Page (Plan B)',
        description:'A simple, elegant, and beautifully responsive landing page theme for websites'
        },
        { image:'https://s3.amazonaws.com/templates.ahostingsolutions.com/agency.png',
        url: 'https://s3.amazonaws.com/templates.ahostingsolutions.com/startbootstrap-agency-gh-pages/index.html',
        title:'Agency (Plan C)',
        description:'A clean, stylish, one page portfolio theme perfect for your agency or small business'
        },
        { image:'https://s3.amazonaws.com/templates.ahostingsolutions.com/businessfrontpage.png',
        url: 'https://s3.amazonaws.com/templates.ahostingsolutions.com/startbootstrap-business-frontpage-gh-pages/index.html',
        title:'Business Front Page (Plan C)',
        description:'A business home page starter template for Bootstrap 5 featuring marketing boxes and a full width image header'},
        { image:'https://s3.amazonaws.com/templates.ahostingsolutions.com/shophomepage.png',
        url: 'https://s3.amazonaws.com/templates.ahostingsolutions.com/startbootstrap-shop-homepage-gh-pages/index.html',
        title:'Shop Home Page (Plan C)',
        description:'A shopping home page starter template featuring products and a shopping cart'},
        { image:'https://s3.amazonaws.com/templates.ahostingsolutions.com/shopitem.png',
        url: 'https://s3.amazonaws.com/templates.ahostingsolutions.com/startbootstrap-shop-item-gh-pages/index.html',
        title:'Shop Item Page (Plan C)',
        description:'A shopping item page starter template featuring products and a shopping cart'}
      ]
}